<modal-dialog-template  *ngIf="isOpen" [modalWidth]="'auto'" [modalHeight]="'auto'" [isOpen]="isOpen" (onEscapeButtonClick)="closeDialog()">
  <div class="layout">
    <div class="layout-header">
      <div class="layout-header__icon"></div>
      <div class="layout-header__text">
        <span [attr.translation-id]="headerTranslationKey">
          {{headerTranslationKey}}
        </span>
      </div>
      <div class="layout-header__close-icon" (click)="closeDialog()"></div>
    </div>
    <div class="layout-content">
      <span [attr.translation-id]="failures[0].errorKey">
        {{ failures[0].errorKey}}
      </span>
    </div>
  </div>
</modal-dialog-template>
