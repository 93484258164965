/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modal-dialog-template/modal-dialog-template.component.ngfactory";
import * as i3 from "../modal-dialog-template/modal-dialog-template.component";
import * as i4 from "../../../../services/click-press-events/click-press-events.service";
import * as i5 from "@angular/common";
import * as i6 from "./error-dialog.component";
import * as i7 from "./error-dialog.service";
var styles_ErrorDialogComponent = [i0.styles];
var RenderType_ErrorDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorDialogComponent, data: {} });
export { RenderType_ErrorDialogComponent as RenderType_ErrorDialogComponent };
function View_ErrorDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "modal-dialog-template", [], null, [[null, "onEscapeButtonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEscapeButtonClick" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalDialogTemplateComponent_0, i2.RenderType_ModalDialogTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i3.ModalDialogTemplateComponent, [i1.ElementRef, i4.ClickPressEventsService], { isOpen: [0, "isOpen"], modalWidth: [1, "modalWidth"], modalHeight: [2, "modalHeight"] }, { onEscapeButtonClick: "onEscapeButtonClick" }), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "div", [["class", "layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "layout-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "layout-header__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "layout-header__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], [[1, "translation-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "layout-header__close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "layout-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], [[1, "translation-id", 0]], null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOpen; var currVal_1 = "auto"; var currVal_2 = "auto"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.headerTranslationKey; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.headerTranslationKey; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.failures[0].errorKey; _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.failures[0].errorKey; _ck(_v, 11, 0, currVal_6); }); }
export function View_ErrorDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOpen; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error-dialog", [], null, null, null, View_ErrorDialogComponent_0, RenderType_ErrorDialogComponent)), i1.ɵdid(1, 245760, null, 0, i6.ErrorDialogComponent, [i7.ErrorDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorDialogComponentNgFactory = i1.ɵccf("error-dialog", i6.ErrorDialogComponent, View_ErrorDialogComponent_Host_0, {}, {}, []);
export { ErrorDialogComponentNgFactory as ErrorDialogComponentNgFactory };
