import { OnInit } from "@angular/core";
import { ErrorDialogService } from "./error-dialog.service";
var ErrorDialogComponent = /** @class */ (function () {
    function ErrorDialogComponent(errorDialogService) {
        this.errorDialogService = errorDialogService;
        this.isOpen = false;
    }
    ErrorDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.errorDialogService.popupShown.subscribe(function (dialogData) {
            if (!dialogData.isOpen) {
                _this.closeDialog();
                return;
            }
            _this.failures = dialogData.failures;
            _this.headerTranslationKey = dialogData.headerTranslationKey;
            _this.onClose = dialogData.onClose;
            _this.isOpen = true;
        });
    };
    ErrorDialogComponent.prototype.closeDialog = function () {
        this.isOpen = false;
        if (this.onClose && typeof this.onClose === 'function') {
            this.onClose();
        }
        this.onClose = null;
    };
    ErrorDialogComponent.prototype.ngOnDestroy = function () {
        this.closeDialog();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    return ErrorDialogComponent;
}());
export { ErrorDialogComponent };
