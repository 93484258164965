/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./register.component";
import * as i4 from "../services/login.service";
var styles_RegisterComponent = [i0.styles];
var RenderType_RegisterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
function View_RegisterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your invitation has expired."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please contact the farm for a new invitation"]))], null, null); }
function View_RegisterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading, please wait..."]))], null, null); }
export function View_RegisterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "register-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "f1-icon f1-sense-time-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SenseHub"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isError; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_RegisterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "register", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i1.ɵdid(1, 114688, null, 0, i3.RegisterComponent, [i4.LoginService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterComponentNgFactory = i1.ɵccf("register", i3.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
