<modal-dialog-template  *ngIf="isOpen" [modalWidth]="'auto'" [modalHeight]="'auto'" [isOpen]="isOpen">
  <div class="layout">
    <div class="layout-header">
      <i class="alert-icon"></i>
      <span class="header">{{texts.header}}</span>
      <copy-password-language-popup [copyPasswordLanguageNames]="languagesNames" (onLanguageChanged)="onLanguageChanged($event)"></copy-password-language-popup>
    </div>
    <div class="layout-content">
      <div class="message-regular">{{texts.contentMessage1}}</div>
      <div class="message-bold">{{texts.contentMessage2}}</div>
      <div class="password-section">
        <div class="password-label">{{texts.password}}</div>
        <input type="text" class="password-input-text" [ngModel]="password" readonly #passwordTextField/>
        <input type="button" [ngModel]="texts.copy" class="copy-button" (click)="onCopyButtonClick(passwordTextField)"/>
      </div>
      <div class="dont-show-message-section">
        <div class="don-show-message" (click)="dontShowToggle()">
          <div class="check-box-icon" [ngClass]="{'selected': dontShow}"></div>
          <label>{{texts.dontShowMessage}}</label>
        </div>
      </div>
    </div>
    <div class="layout-footer">
      <input type="button" [ngModel]="texts.closeButton" class="close-button" (click)="onCloseButtonClick()"/>
    </div>
  </div>
</modal-dialog-template>
