import {Inject, Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {debounceTime, filter} from 'rxjs/operators';

enum Key {
  Enter = 'Enter',
  Escape = 'Escape',
}

@Injectable({
  providedIn: 'root'
})
export class ClickPressEventsService {

  public documentClick: Observable<MouseEvent> = new Observable<MouseEvent>();

  private keyDown: Observable<KeyboardEvent> = new Observable<KeyboardEvent>();

  private keyUp: Observable<KeyboardEvent> = new Observable<KeyboardEvent>();

  private resize: Observable<Event>;

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this.documentClick = fromEvent<MouseEvent>(this._document, 'click')
      .pipe(
        filter((event) => event.target['parentElement'] != null),
      );
    this.keyDown = fromEvent<KeyboardEvent>(this._document, 'keydown');
    this.keyUp = fromEvent<KeyboardEvent>(this._document, 'keyup');
    this.resize = fromEvent(window, 'resize');
  }

  public get keydownEscape(): Observable<Event> {
    return this.keyDown.pipe(
      filter((event) => event.key === Key.Escape),
    );
  }

  public get keyupEnter(): Observable<Event> {
    return this.keyUp.pipe(
      filter((event) => event.key === Key.Enter),
    );
  }

  public get windowResize(): Observable<Event> {
    return this.resize.pipe(
      debounceTime(100),
    );
  }
}
