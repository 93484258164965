import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {
  HeaderAuthorizationDetails,
  HttpResponseResult,
  LoginResult,
  LoginService, OktaUserDetails,
  TokenHeaderAuthorization
} from '../services/login.service';
import {ErrorDialogService, Failure} from "../common/components/dialogs/error-dialog/error-dialog.service";
import {CookieService} from 'ngx-cookie';

declare let branch : any;

class ExternalLinkUserData {
  public farmID: string;
  public email: string;
}

class ExchangeTokenData {
  public userName : string;
  public farmId : string;
  public token : string;
}

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username: string;

  public password: string;

  public farmId: string;

  public isLoading: boolean;

  public rememberMe: boolean;

  public errorMessage: string;

  private static readonly EnterKey = 'Enter';

  private static readonly BotheringCookieKeyPrefix = 'migration-reminder';

  private static readonly UserNameRegex = /[^a-zA-Z0-9 \.\@\_\-]/g;

  private static readonly FarmIdRegex = /[^a-zA-Z0-9]/g;

  private static readonly DontShowMigrationPasswordDialogKey = 'dontShowMigrationPassword';

  private authorizedUrlLoginV4 : string;

  public userNameError : boolean;

  public passwordError : boolean;

  public farmIdError : boolean;

  public isPasswordVisible: boolean;

  public copyPasswordDialogIsOpen: boolean;

  @Input()
  public fromExternalLink : boolean;

  constructor(private loginService:LoginService,
              private readonly cookieService: CookieService,
              private readonly errorDialogService: ErrorDialogService,
              private readonly changeDetectorRef: ChangeDetectorRef) {

  }

  async ngOnInit() {
    await this.loginService.loadConfig();
    if (this.fromExternalLink) {
      branch.init(this.loginService.reverseProxyConfig.branchIoKey, async (err, data) => {
        let externalLinkUserData: ExternalLinkUserData = <ExternalLinkUserData>data.data_parsed.$custom_meta_tags;
        this.username = externalLinkUserData.email;
        this.farmId = externalLinkUserData.farmID;
        this.password = '';
        await this.loginService.getReverseProxyDomain(this.farmId);
        this.changeDetectorRef.detectChanges();
      });
    } else {
      if (!await this.performLoginByExchangeToken()) {
        const _p = this.getParameterByName('_p');
        if (this.decodePassword(_p)) {
          await this.performLogin();
        } else {
          // check if remember me option is on for token based versions
          const userCredentialsAfterTokenSupport = window.localStorage.getItem('uc');
          if (userCredentialsAfterTokenSupport != null) {
            const tokenBasedUserCredentials: HeaderAuthorizationDetails = JSON.parse(atob(userCredentialsAfterTokenSupport));
            if (tokenBasedUserCredentials) {
              this.username = tokenBasedUserCredentials.userName;
              this.password = tokenBasedUserCredentials.password;
              this.farmId = tokenBasedUserCredentials.farmId;
              this.rememberMe = true;
            }
          } else {
            // check if remember me option is on for non-token based version (older than 5.2)
            const userCredentials = window.localStorage.getItem('ucrp');
            if (userCredentials != null) {
              const splitUserCredentials = atob(userCredentials).split('_');
              this.username = splitUserCredentials[0];
              this.password = splitUserCredentials[1];
              this.farmId = splitUserCredentials[2];
              this.rememberMe = true;
            }
          }
        }
      }
    }
  }

  public validateUserName($event: KeyboardEvent) {
    this.userNameError = false;
    if ($event.key !== LoginComponent.EnterKey) {
      if(this.username && LoginComponent.UserNameRegex.test(this.username)) {
        this.username = this.username.replace( LoginComponent.UserNameRegex,"");
      }
      if(this.username && this.username.length > 120) {
        this.username = this.username.slice(0,120);
      }
    }
  }

  public validateFarmId($event: KeyboardEvent) {
    this.farmIdError = false;
    if ($event.key !== LoginComponent.EnterKey) {
      if(this.farmId && LoginComponent.FarmIdRegex.test(this.farmId)) {
        this.farmId = this.farmId.replace( LoginComponent.FarmIdRegex,"");
      }
      if(this.farmId && this.farmId.length > 120) {
        this.farmId = this.farmId.slice(0,120);
      }
    }
  }

  public passwordChanged() {
    this.passwordError = false;
  }

  public toggleRememberMe() {
    this.rememberMe = !this.rememberMe;
  }

  public async performLoginByExchangeToken() : Promise<boolean> {
    let tokenParameter = this.getParameterByName('_t');
    if(!tokenParameter) {
      return false;
    }
    let tokenJson : string = atob(tokenParameter);
    let exchangeTokenData : ExchangeTokenData = JSON.parse(tokenJson);
    await this.loginService.getReverseProxyDomain(exchangeTokenData.farmId);
    let exhcangeTokenResult = await this.loginService.performExchangeToken(exchangeTokenData.token, exchangeTokenData.farmId);
    if(exhcangeTokenResult.status == 200) {
      let authDetails: TokenHeaderAuthorization = {
        accessToken: exhcangeTokenResult.responseBody.accessToken,
        farmId: exchangeTokenData.farmId,
        name: exhcangeTokenResult.responseBody.userName
      };
      const tokenAuthEncoded = window.btoa(JSON.stringify(authDetails));
      window.sessionStorage.setItem('uc', tokenAuthEncoded);
      const authorizedUrl = `${this.loginService.getOriginUrl}/${exhcangeTokenResult.responseBody.displayVersion}/`;
      window.location.href = authorizedUrl;
    } else {
      let showDialog = exhcangeTokenResult.status == 429;
      this.handleErrorResponse(exhcangeTokenResult.status, exhcangeTokenResult.statusText, showDialog);
    }
    return true;
  }

  public async performLogin() {
    const region:string = 'IL01';
    this.userNameError = false;
    this.passwordError = false;
    this.farmIdError = false;
    let hasEmptyFields = false;
    if(this.username == null ||
      this.username.trim() == '') {
      hasEmptyFields = true;
      this.userNameError = true;
    }
    if(this.password == null ||
      this.password.trim() == '') {
      hasEmptyFields = true;
      this.passwordError = true;
    }
    if(this.farmId == null ||
      this.farmId.trim() == '') {
      hasEmptyFields = true;
      this.farmIdError = true;
    }
    if(hasEmptyFields) {
      return;
    }
    this.isLoading = true;
    try {
      await this.loginService.getReverseProxyDomain(this.farmId);
      let tokenLoginResponse = await this.loginService.performLoginV4(this.username, this.farmId, region, this.password);
      if(tokenLoginResponse.status != 404) {
        await this.handleLoginV4(tokenLoginResponse);
      } else {
        let versionStatusResponse = await this.loginService.getVersionStatus(this.username, this.farmId, region, this.password);
        if (versionStatusResponse.status == 200) {
          if (versionStatusResponse.responseBody != null &&
            versionStatusResponse.responseBody.senseTimeVersion != null) {

              let loginV2Response = await this.loginService.performLoginV2(this.username, this.farmId, region, this.password);
              if (loginV2Response.status == 200) {

                let configurationResponse = await this.loginService.performLoadConfiguration(this.username, this.farmId, region, this.password);
                if (configurationResponse.status == 200) {

                  this.storeUserDetails(region);

                  let configuration = configurationResponse.responseBody;

                  let apiVersion = configuration.displayVersion;
                  const authorizedUrl = `${this.loginService.getOriginUrl}/${apiVersion}/`;

                  configuration.farmRole = configuration.user.farmRole;
                  configuration.username = configuration.user.username;
                  configuration.firstName = configuration.user.firstName;
                  configuration.lastName = configuration.user.lastName;
                  configuration.lastLoggedInTime = new Date();
                  configuration.appVersion = "";
                  configuration.authorizedUrl = authorizedUrl;

                  window.sessionStorage.setItem('handleResponsePolls', JSON.stringify(configuration.polls || {}));
                  window.sessionStorage.setItem('_UserInfo', this.loginService.btoa64(JSON.stringify({
                    authorization: this.loginService.encodeCredentials(this.username, this.farmId, region, this.password),
                    userData: configuration
                  })));
                  let headerAuthDetails = new HeaderAuthorizationDetails();
                  headerAuthDetails.farmId = this.farmId;
                  headerAuthDetails.userName = this.username;
                  headerAuthDetails.password = this.password;
                  this.loginService.storeAngular7PlusClientAuthDetails(headerAuthDetails, this.rememberMe);
                  window.location.href = authorizedUrl;

                } else {
                  if (configurationResponse.status === 401) {
                    this.errorMessage = 'Unauthorized';
                  } else {
                    this.errorMessage = configurationResponse.statusText;
                  }
                }
              } else {
                let showDialog = loginV2Response.status == 400;
                this.handleErrorResponse(loginV2Response.status, loginV2Response.statusText, showDialog);
              }
            } else {

              let loginResponse = await this.loginService.performLogin(this.username, this.farmId, region, this.password);
              if (loginResponse.status == 200) {

                this.storeUserDetails(region);

                let apiVersion = loginResponse.responseBody.version;
                let validVersion = this.loginService.validateVersion(loginResponse.responseBody.displayVersion, '1.1.2.27438', []);
                if (validVersion > 0) {
                  apiVersion = loginResponse.responseBody.displayVersion;
                }

                const authorizedUrl = `${this.loginService.getOriginUrl}/${apiVersion}/`;

                window.sessionStorage.setItem('handleResponsePolls', JSON.stringify(loginResponse.responseBody.polls || {}));

                const userData = {
                  bizModeParameters: loginResponse.responseBody.bizModeParameters,
                  thirdPartyParameters: loginResponse.responseBody.thirdPartyParameters,
                  isBugReportEnabled: loginResponse.responseBody.isBugReportEnabled,
                  customerId: loginResponse.responseBody.customerId,
                  dealerId: loginResponse.responseBody.dealerId,
                  farmPackage: loginResponse.responseBody.farmPackage,
                  farmId: loginResponse.responseBody.farmId,
                  serverStatus: loginResponse.responseBody.serverStatus,
                  systemState: 'active',
                  serverStatusEndDate: loginResponse.responseBody.serverStatusEndDate,
                  farmRole: loginResponse.responseBody.user.farmRole,
                  user: loginResponse.responseBody.user,
                  username: loginResponse.responseBody.user.username,
                  firstName: loginResponse.responseBody.user.firstName,
                  farmName: loginResponse.responseBody.farmName,
                  lastName: loginResponse.responseBody.user.lastName,
                  lastLoggedInTime: new Date(),
                  appVersion: loginResponse.responseBody.version,
                  displayVersion: loginResponse.responseBody.displayVersion,
                  originalResponse: loginResponse.responseBody,
                  authorizedUrl: authorizedUrl
                };

                window.sessionStorage.setItem('_UserInfo', this.loginService.btoa64(JSON.stringify({
                  authorization: this.loginService.encodeCredentials(this.username, this.farmId, region, this.password),
                  userData
                })));
                window.location.href = authorizedUrl;

              } else {
                let showDialog = loginResponse.status == 400;
                this.handleErrorResponse(loginResponse.status, loginResponse.statusText, showDialog);
              }
            }
          } else {
            this.errorMessage = versionStatusResponse.statusText;
          }
        }
    } finally {
      this.isLoading = false;
    }
  }

  public copyPasswordDialogClose(dontShow:boolean) {
    if(dontShow) {
      window.localStorage.setItem(LoginComponent.DontShowMigrationPasswordDialogKey, 'true');
    } else {
      window.localStorage.removeItem(LoginComponent.DontShowMigrationPasswordDialogKey);
    }
    this.copyPasswordDialogIsOpen = false;
    this.afterLoginV4NavigateToClient();
  }

  private async handleLoginV4(tokenLoginResponse: HttpResponseResult<LoginResult>) {
    if (tokenLoginResponse.status == 200) {
      let authDetails: TokenHeaderAuthorization = {
        accessToken: tokenLoginResponse.responseBody.accessToken,
        farmId: this.farmId,
        name: this.username
      };
      const tokenAuthEncoded = window.btoa(JSON.stringify(authDetails));
      window.sessionStorage.setItem('uc', tokenAuthEncoded);
      if (tokenLoginResponse.responseBody.refreshToken) {
        window.sessionStorage.setItem('ucr', tokenLoginResponse.responseBody.refreshToken);
      }
      // check if user needs to sign on eula / privacy policy and write at localstorage so it will be shown to the user.
      if (tokenLoginResponse.responseBody.privacyPolicy || tokenLoginResponse.responseBody.eula) {
        this.loginService.storeDocumentDetails(tokenLoginResponse.responseBody);
      }
      // check if remember me is toggled on - if so - store the auth details for the RP for token based versions (5.2 and on).
      if (this.rememberMe) {
        this.loginService.storeAngularTokenBasedClientDetails({userName: this.username, password: this.password, farmId: this.farmId});
      } else {
        this.loginService.removeStoredAngularTokenBasedClientDetails();
      }
      // clear the migration popup prompt cookie (if exists) - so it will be shown on login for specified user on specified farm
      this.cookieService.remove(LoginComponent.BotheringCookieKeyPrefix + this.farmId + tokenLoginResponse.responseBody.userId);
      this.cookieService.remove(LoginComponent.BotheringCookieKeyPrefix + this.farmId + this.username);

      // add okta session cookie registration on login from RP
      const oktaLoginDetails: OktaUserDetails = new OktaUserDetails();
      oktaLoginDetails.setOktaUserDetails(this.username, this.password);
      this.authorizedUrlLoginV4 = `${this.loginService.getOriginUrl}/${tokenLoginResponse.responseBody.displayVersion}/`;
      let navigationUrl = await this.loginService.getOktaAccessCookieLink(oktaLoginDetails, `${this.loginService.getOriginUrl}/`);
      if (navigationUrl) {
        const version = btoa(tokenLoginResponse.responseBody.displayVersion);
        window.localStorage.setItem('lvn', version);
        this.authorizedUrlLoginV4 = navigationUrl;
      }

      let dontShowMigrationPassword = window.localStorage.getItem(LoginComponent.DontShowMigrationPasswordDialogKey);
      if(dontShowMigrationPassword == null) {
        let response = await this.loginService.getIsUserMigrated(authDetails);
        if(response.status == 200 &&
           !response.responseBody.isUserMigrated) {
          this.showCopyPasswordDialog();
          return;
        }
      }
      this.afterLoginV4NavigateToClient();
    } else {
      let showDialog = tokenLoginResponse.status == 429;
      this.handleErrorResponse(tokenLoginResponse.status, tokenLoginResponse.statusText, showDialog);
    }
  }

  public togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  private afterLoginV4NavigateToClient() {
    window.location.href = this.authorizedUrlLoginV4;
  }

  private getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  private decodePassword(_v) : boolean {
    if (!_v)
      return false;

    const decoded = atob(_v);
    const splitArr = decoded.split(/[\W_]+/);

    this.username = splitArr[0];
    this.password = splitArr[3];
    this.farmId = splitArr[1];
    return true;
  }

  private storeUserDetails(region: string) {
    if (this.rememberMe) {
      window.localStorage.setItem('ucrp', window.btoa(`${this.username}_${this.password}_${this.farmId}_${region}`));
    } else {
      window.localStorage.removeItem('ucrp');
    }
    window.localStorage.removeItem('uc');
  }

  private handleErrorResponse(status: number, statusText: string, showDialog : boolean) {
    if (status === 401) {
      this.errorMessage = 'Unauthorized';
    } else if (status === 502) {
      this.errorMessage = 'No network connection, failed to login';
    } else if (status === 503) {
      this.errorMessage = 'Wrong farm ID';
    } else if (showDialog) {
      this.errorMessage = null;
      setTimeout(() => {
        let failures: Failure[] = [{
          errorKey: 'You have exceeded the maximum number of Login Attempts. Please try again in 15 minutes.'
        }];
        this.errorDialogService.show('Too many Failed Login Attempts', failures, null);
      }, 200);
    } else {
      this.errorMessage = statusText;
    }
  }

  private showCopyPasswordDialog() {
    this.copyPasswordDialogIsOpen = true;
  }
}
