import { EventEmitter, OnInit } from '@angular/core';
import { CopyPasswordLanguages } from '../copy-password-dialog.component';
var CopyPasswordLanguagePopupComponent = /** @class */ (function () {
    function CopyPasswordLanguagePopupComponent() {
        this.onLanguageChanged = new EventEmitter();
        this.CopyPasswordLanguages = CopyPasswordLanguages;
        this.selectedLanguage = CopyPasswordLanguages[0];
    }
    CopyPasswordLanguagePopupComponent.prototype.ngOnInit = function () {
    };
    CopyPasswordLanguagePopupComponent.prototype.getLanguageName = function (locale) {
        return this.copyPasswordLanguageNames[locale];
    };
    CopyPasswordLanguagePopupComponent.prototype.togglePopup = function () {
        this.isOpen = !this.isOpen;
    };
    CopyPasswordLanguagePopupComponent.prototype.selectLanguage = function (locale) {
        this.selectedLanguage = locale;
        this.isOpen = false;
        this.onLanguageChanged.emit(this.selectedLanguage);
    };
    return CopyPasswordLanguagePopupComponent;
}());
export { CopyPasswordLanguagePopupComponent };
