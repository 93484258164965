import {Component, OnInit} from "@angular/core";
import {IErrorDialogData, ErrorDialogService, Failure} from "./error-dialog.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  public subscription: Subscription;

  public isOpen: boolean = false;

  public failures!: Failure[];

  public headerTranslationKey: string;

  private onClose: () => void;

  constructor(private readonly errorDialogService: ErrorDialogService) {}

  public ngOnInit(): void {
    this.subscription = this.errorDialogService.popupShown.subscribe((dialogData: IErrorDialogData) => {
      if (!dialogData.isOpen) {
        this.closeDialog();
        return;
      }
      this.failures = dialogData.failures;
      this.headerTranslationKey = dialogData.headerTranslationKey;
      this.onClose = dialogData.onClose;
      this.isOpen = true;
    });
  }

  public closeDialog(): void {
    this.isOpen = false;
    if (this.onClose && typeof this.onClose === 'function') {
      this.onClose();
    }
    this.onClose = null;
  }

  public ngOnDestroy(): void {
    this.closeDialog();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
