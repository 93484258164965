var AppComponent = /** @class */ (function () {
    function AppComponent() {
        console.log(window.location);
        if (window.location.search.toString().indexOf('?register') >= 0) {
            this.isRegister = true;
        }
        else if (window.location.search.toString().indexOf('?existing-user') >= 0) {
            this.loginFromExternalLink = true;
        }
        else {
            var oktaVersionNavigationString = window.localStorage.getItem('lvn');
            if (oktaVersionNavigationString) {
                window.localStorage.removeItem('lvn');
                var parsedOktaVersionNavigation = atob(oktaVersionNavigationString);
                window.location.href = "" + window.location.origin + window.location.pathname + parsedOktaVersionNavigation + "/";
            }
        }
    }
    return AppComponent;
}());
export { AppComponent };
