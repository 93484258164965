/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copy-password-language-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./copy-password-language-popup.component";
var styles_CopyPasswordLanguagePopupComponent = [i0.styles];
var RenderType_CopyPasswordLanguagePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyPasswordLanguagePopupComponent, data: {} });
export { RenderType_CopyPasswordLanguagePopupComponent as RenderType_CopyPasswordLanguagePopupComponent };
function View_CopyPasswordLanguagePopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "language-list-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "language-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ("lang-icon-" + _v.context.$implicit); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getLanguageName(_v.context.$implicit); _ck(_v, 5, 0, currVal_1); }); }
function View_CopyPasswordLanguagePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "languages-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyPasswordLanguagePopupComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CopyPasswordLanguages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CopyPasswordLanguagePopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "selected-language"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "language-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyPasswordLanguagePopupComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("lang-icon-" + _co.selectedLanguage); _ck(_v, 3, 0, currVal_0); var currVal_2 = (_co.isOpen ? "dropdown-close" : "dropdown-open"); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isOpen; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getLanguageName(_co.selectedLanguage); _ck(_v, 5, 0, currVal_1); }); }
export function View_CopyPasswordLanguagePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "copy-password-language-popup", [], null, null, null, View_CopyPasswordLanguagePopupComponent_0, RenderType_CopyPasswordLanguagePopupComponent)), i1.ɵdid(1, 114688, null, 0, i3.CopyPasswordLanguagePopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CopyPasswordLanguagePopupComponentNgFactory = i1.ɵccf("copy-password-language-popup", i3.CopyPasswordLanguagePopupComponent, View_CopyPasswordLanguagePopupComponent_Host_0, { copyPasswordLanguageNames: "copyPasswordLanguageNames" }, { onLanguageChanged: "onLanguageChanged" }, []);
export { CopyPasswordLanguagePopupComponentNgFactory as CopyPasswordLanguagePopupComponentNgFactory };
