import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var Key;
(function (Key) {
    Key["Enter"] = "Enter";
    Key["Escape"] = "Escape";
})(Key || (Key = {}));
var ClickPressEventsService = /** @class */ (function () {
    function ClickPressEventsService(_document) {
        this._document = _document;
        this.documentClick = new Observable();
        this.keyDown = new Observable();
        this.keyUp = new Observable();
        this.documentClick = fromEvent(this._document, 'click')
            .pipe(filter(function (event) { return event.target['parentElement'] != null; }));
        this.keyDown = fromEvent(this._document, 'keydown');
        this.keyUp = fromEvent(this._document, 'keyup');
        this.resize = fromEvent(window, 'resize');
    }
    Object.defineProperty(ClickPressEventsService.prototype, "keydownEscape", {
        get: function () {
            return this.keyDown.pipe(filter(function (event) { return event.key === Key.Escape; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClickPressEventsService.prototype, "keyupEnter", {
        get: function () {
            return this.keyUp.pipe(filter(function (event) { return event.key === Key.Enter; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClickPressEventsService.prototype, "windowResize", {
        get: function () {
            return this.resize.pipe(debounceTime(100));
        },
        enumerable: true,
        configurable: true
    });
    ClickPressEventsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClickPressEventsService_Factory() { return new ClickPressEventsService(i0.ɵɵinject(i1.DOCUMENT)); }, token: ClickPressEventsService, providedIn: "root" });
    return ClickPressEventsService;
}());
export { ClickPressEventsService };
