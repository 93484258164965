import { ElementRef } from '@angular/core';
import * as i0 from "@angular/core";
var PopupViewStateService = /** @class */ (function () {
    function PopupViewStateService() {
        this.registeredComponents = new Map();
        // tslint:disable-next-line:no-any
        this.outsideClickIgnoreElements = new Set();
    }
    PopupViewStateService.prototype.registerComponent = function (name, element) {
        this.registeredComponents.set(name, element);
    };
    PopupViewStateService.prototype.unregisterComponent = function (name) {
        this.registeredComponents.delete(name);
    };
    PopupViewStateService.prototype.getComponent = function (name) {
        if (this.registeredComponents.has(name)) {
            return this.registeredComponents.get(name);
        }
        return null;
    };
    // tslint:disable-next-line:no-any
    PopupViewStateService.prototype.registerOutsideClickIgnoreElement = function (element) {
        this.outsideClickIgnoreElements.add(element);
    };
    // tslint:disable-next-line:no-any
    PopupViewStateService.prototype.unregisterOutsideClickIgnoreElement = function (element) {
        if (this.outsideClickIgnoreElements.has(element)) {
            this.outsideClickIgnoreElements.delete(element);
        }
    };
    // tslint:disable-next-line:no-any
    PopupViewStateService.prototype.partOfOutsideClickIgnoreElement = function (element) {
        if (this.outsideClickIgnoreElements.has(element)) {
            return true;
        }
        else {
            return Array.from(this.outsideClickIgnoreElements.values()).some(function (value) { return value.contains(element); });
        }
    };
    PopupViewStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupViewStateService_Factory() { return new PopupViewStateService(); }, token: PopupViewStateService, providedIn: "root" });
    return PopupViewStateService;
}());
export { PopupViewStateService };
