import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IReverseProxyConfig} from '../../../../services/login.service';

export const CopyPasswordLanguages = ["en-us","fr-fr","ru-ru","de-de","it-it","nl-nl","da-dk","sv-se","nb-no","fi-fi","el-gr","es-mx","pt-pt","zh-chs","ko-kr"];

interface CopyPasswordDialogTexts {
  header:string;
  contentMessage1:string;
  contentMessage2:string;
  password:string;
  copy:string;
  dontShowMessage:string;
  closeButton:string;
}

@Component({
  selector: 'copy-password-dialog',
  templateUrl: './copy-password-dialog.component.html',
  styleUrls: ['./copy-password-dialog.component.scss']
})
export class CopyPasswordDialogComponent implements OnInit {

  public texts: CopyPasswordDialogTexts;

  @Input()
  public isOpen: boolean = false;

  @Input()
  public password: string;

  @Output()
  public onClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dontShow: boolean;

  public selectedLanguage: string = 'en-us';

  public languagesNames: any;

  public languagesTexts: any;

  constructor(private readonly httpClient:HttpClient) {

  }

  public async ngOnInit() {
    this.languagesNames = await this.httpClient.get('assets/translations/copy-password-languages.json').toPromise();
    this.languagesTexts = await this.httpClient.get('assets/translations/copy-password-texts.json').toPromise();
    this.onLanguageChanged(this.selectedLanguage);
  }

  public onCloseButtonClick() {
    this.onClosed.emit(this.dontShow);
  }

  public onCopyButtonClick(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  public dontShowToggle() {
    this.dontShow = !this.dontShow;
  }

  public onLanguageChanged(language:string) {
    this.selectedLanguage = language;
    this.texts = this.languagesTexts[this.selectedLanguage];
  }
}
