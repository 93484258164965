<copy-password-dialog [isOpen]="copyPasswordDialogIsOpen"
                      [password]="password"
                      (onClosed)="copyPasswordDialogClose($event)"></copy-password-dialog>
<error-dialog></error-dialog>
<div class="main-app">
  <form name="loginForm" class="login-form" autocomplete="off" (submit)="performLogin()">
    <div class="login-form-container">
      <div class="form-field-label">
        <div class="menu-header">
          <div class="app-logo"></div>
        </div>
      </div>
      <div class="form-field-title">
        <label sh-id="username-label">Username / Email</label>
      </div>
      <div class="form-field">
        <input (keydown)="validateUserName($event)"
               (keyup)="validateUserName($event)"
               [ngClass]="{'error-input-field' : userNameError}"
               sh-id="login_username"
               type="text"
               required="required"
               name="username"
               placeholder="Username"
               autocomplete="off"
               (ngModelChange)="userNameError = false"
               [(ngModel)]="username"/>
      </div>
      <div class="form-field-title">
        <label sh-id="password-label">Password</label>
      </div>
      <div class="form-field">
        <input sh-id="login_password"
               [attr.type]="isPasswordVisible ? 'text':'password'"
               required="required"
               name="password"
               placeholder="Password"
               autocomplete="off"
               (keydown)="passwordChanged()"
               (keyup)="passwordChanged()"
               [ngClass]="{'error-input-field' : passwordError}"
               (ngModelChange)="passwordError = false"
               [(ngModel)]="password"/>
        <i class="password-visibility"
           [ngClass]="{'show-password':!isPasswordVisible,'hide-password':isPasswordVisible}"
           (click)="togglePasswordVisibility()"></i>
      </div>
      <div class="form-field-title">
        <label sh-id="farmid-label">Farm ID</label>
      </div>
      <div class="form-field">
        <input (keydown)="validateFarmId($event)"
               (keyup)="validateFarmId($event)"
               type="text"
               required="required"
               name="farmId"
               placeholder="Farm ID"
               autocomplete="off"
               [ngClass]="{'error-input-field' : farmIdError}"
               (ngModelChange)="farmIdError = false"
               [(ngModel)]="farmId"/>
      </div>
      <div sh-id="rememberme-checkbox" class="form-field form-field-title remember-me" [ngClass]="{'selected': rememberMe}" (click)="toggleRememberMe()">
        <div class="f1-icon f1-icon-un-check-box"></div>
        <label>Remember me</label>
      </div>

      <div class="form-field form-field-error">
        <label id='errorMessage'>{{errorMessage}}</label>
      </div>

      <div class="form-field form-field-btn">
        <input sh-id="login_loginButton" type="submit" value="Login" class="login-btn"/>
      </div>
    </div>
    <div class="frame-corner"></div>
    <div id="isLoaded" class="loader" *ngIf="isLoading"></div>
  </form>
  <div class="login-section">
    <div class="f1-icon f1-sense-time-logo"></div>
    <div class="login-text-header">Welcome to <span sh-id="welcome-text-header-div">SenseHub</span></div>
  </div>
</div>
