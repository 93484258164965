/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login/login.component.ngfactory";
import * as i3 from "./login/login.component";
import * as i4 from "./services/login.service";
import * as i5 from "ngx-cookie";
import * as i6 from "./common/components/dialogs/error-dialog/error-dialog.service";
import * as i7 from "./register/register.component.ngfactory";
import * as i8 from "./register/register.component";
import * as i9 from "@angular/common";
import * as i10 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "login", [], null, null, null, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginComponent, [i4.LoginService, i5.CookieService, i6.ErrorDialogService, i1.ChangeDetectorRef], { fromExternalLink: [0, "fromExternalLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loginFromExternalLink; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "register", [], null, null, null, i7.View_RegisterComponent_0, i7.RenderType_RegisterComponent)), i1.ɵdid(1, 114688, null, 0, i8.RegisterComponent, [i4.LoginService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isRegister; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isRegister; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i10.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
