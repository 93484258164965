import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoginService} from "../services/login.service";

declare let branch : any;

class RegisterUserData {
  public farmID: string;
  public email: string;
  public pin: string;
}

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public isLoading : boolean = true;

  public isError: boolean = false;

  constructor(private readonly loginService:LoginService,
              private readonly changeDetectorRef: ChangeDetectorRef) {

  }

  async ngOnInit() {
    // init Branch
    await this.loginService.loadConfig();
    branch.init(this.loginService.reverseProxyConfig.branchIoKey, async (err, data) => {
      try {
        let registerUserData : RegisterUserData = <RegisterUserData>data.data_parsed.$custom_meta_tags;
        window.sessionStorage.setItem('cnu', JSON.stringify(registerUserData));
        await this.loginService.getReverseProxyDomain(registerUserData.farmID);
        let versionResponse = await this.loginService.getVersionAnonymous(registerUserData.farmID);
        const authorizedUrl = `${this.loginService.getOriginUrl}/${versionResponse.responseBody.senseTimeVersion}/`;
        window.location.href = authorizedUrl;
      } catch {
        this.isLoading = false;
        this.isError = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }
}
