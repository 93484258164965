<div class="selected-language" (click)="togglePopup()">
  <div [ngClass]="'lang-icon-' + selectedLanguage"></div>
  <div class="language-name">{{getLanguageName(selectedLanguage)}}</div>
  <div [ngClass]="isOpen ? 'dropdown-close' : 'dropdown-open'"></div>
</div>
<div class="languages-list" *ngIf="isOpen">
  <div class="language-list-item" *ngFor="let lang of CopyPasswordLanguages" (click)="selectLanguage(lang)">
    <div [ngClass]="'lang-icon-' + lang"></div>
    <div class="language-name">{{getLanguageName(lang)}}</div>
  </div>
</div>
