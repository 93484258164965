import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClickPressEventsService } from "../../../../services/click-press-events/click-press-events.service";
var ModalDialogTemplateComponent = /** @class */ (function () {
    function ModalDialogTemplateComponent(element, clickPressEventsService) {
        this.element = element;
        this.clickPressEventsService = clickPressEventsService;
        this.isOpen = false;
        this.modalWidth = 555;
        this.modalHeight = 555;
        this.overlayColor = '';
        this.outsideModalClick = new EventEmitter();
        this.onEscapeButtonClick = new EventEmitter();
        this.onEnterButtonClick = new EventEmitter();
        this.subscriptions = new Subscription();
    }
    ModalDialogTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        document.body.appendChild(this.element.nativeElement);
        this.subscriptions.add(this.clickPressEventsService.keyupEnter.subscribe(function () { return _this.enterClick(); }));
        this.subscriptions.add(this.clickPressEventsService.keydownEscape.subscribe(function () { return _this.escapeClick(); }));
    };
    ModalDialogTemplateComponent.prototype.ngOnDestroy = function () {
        if (this.element.nativeElement && this.element.nativeElement.parentNode == document.body) {
            document.body.removeChild(this.element.nativeElement);
        }
        this.subscriptions.unsubscribe();
    };
    ModalDialogTemplateComponent.prototype.escapeClick = function () {
        if (this.isOpen) {
            this.onEscapeButtonClick.emit();
        }
    };
    ModalDialogTemplateComponent.prototype.enterClick = function () {
        if (this.isOpen) {
            this.onEnterButtonClick.emit();
        }
    };
    Object.defineProperty(ModalDialogTemplateComponent.prototype, "width", {
        get: function () {
            var w;
            if (typeof this.modalWidth === 'string') {
                w = this.modalWidth;
            }
            if (typeof this.modalWidth === 'number') {
                w = this.modalWidth + 'px';
            }
            return w;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModalDialogTemplateComponent.prototype, "height", {
        get: function () {
            if (this.modalHeight === 'auto') {
                return this.modalHeight;
            }
            var h;
            if (typeof this.modalHeight === 'string') {
                h = this.modalHeight;
            }
            if (typeof this.modalHeight === 'number') {
                h = this.modalHeight + 'px';
            }
            return h;
        },
        enumerable: true,
        configurable: true
    });
    ModalDialogTemplateComponent.prototype.onOutsideModalClick = function () {
        this.outsideModalClick.emit();
    };
    return ModalDialogTemplateComponent;
}());
export { ModalDialogTemplateComponent };
