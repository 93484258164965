var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { HeaderAuthorizationDetails, LoginService, OktaUserDetails } from '../services/login.service';
import { ErrorDialogService } from "../common/components/dialogs/error-dialog/error-dialog.service";
import { CookieService } from 'ngx-cookie';
var ExternalLinkUserData = /** @class */ (function () {
    function ExternalLinkUserData() {
    }
    return ExternalLinkUserData;
}());
var ExchangeTokenData = /** @class */ (function () {
    function ExchangeTokenData() {
    }
    return ExchangeTokenData;
}());
var LoginComponent = /** @class */ (function () {
    function LoginComponent(loginService, cookieService, errorDialogService, changeDetectorRef) {
        this.loginService = loginService;
        this.cookieService = cookieService;
        this.errorDialogService = errorDialogService;
        this.changeDetectorRef = changeDetectorRef;
    }
    LoginComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _p, userCredentialsAfterTokenSupport, tokenBasedUserCredentials, userCredentials, splitUserCredentials;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loginService.loadConfig()];
                    case 1:
                        _a.sent();
                        if (!this.fromExternalLink) return [3 /*break*/, 2];
                        branch.init(this.loginService.reverseProxyConfig.branchIoKey, function (err, data) { return __awaiter(_this, void 0, void 0, function () {
                            var externalLinkUserData;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        externalLinkUserData = data.data_parsed.$custom_meta_tags;
                                        this.username = externalLinkUserData.email;
                                        this.farmId = externalLinkUserData.farmID;
                                        this.password = '';
                                        return [4 /*yield*/, this.loginService.getReverseProxyDomain(this.farmId)];
                                    case 1:
                                        _a.sent();
                                        this.changeDetectorRef.detectChanges();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 6];
                    case 2: return [4 /*yield*/, this.performLoginByExchangeToken()];
                    case 3:
                        if (!!(_a.sent())) return [3 /*break*/, 6];
                        _p = this.getParameterByName('_p');
                        if (!this.decodePassword(_p)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.performLogin()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        userCredentialsAfterTokenSupport = window.localStorage.getItem('uc');
                        if (userCredentialsAfterTokenSupport != null) {
                            tokenBasedUserCredentials = JSON.parse(atob(userCredentialsAfterTokenSupport));
                            if (tokenBasedUserCredentials) {
                                this.username = tokenBasedUserCredentials.userName;
                                this.password = tokenBasedUserCredentials.password;
                                this.farmId = tokenBasedUserCredentials.farmId;
                                this.rememberMe = true;
                            }
                        }
                        else {
                            userCredentials = window.localStorage.getItem('ucrp');
                            if (userCredentials != null) {
                                splitUserCredentials = atob(userCredentials).split('_');
                                this.username = splitUserCredentials[0];
                                this.password = splitUserCredentials[1];
                                this.farmId = splitUserCredentials[2];
                                this.rememberMe = true;
                            }
                        }
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.validateUserName = function ($event) {
        this.userNameError = false;
        if ($event.key !== LoginComponent.EnterKey) {
            if (this.username && LoginComponent.UserNameRegex.test(this.username)) {
                this.username = this.username.replace(LoginComponent.UserNameRegex, "");
            }
            if (this.username && this.username.length > 120) {
                this.username = this.username.slice(0, 120);
            }
        }
    };
    LoginComponent.prototype.validateFarmId = function ($event) {
        this.farmIdError = false;
        if ($event.key !== LoginComponent.EnterKey) {
            if (this.farmId && LoginComponent.FarmIdRegex.test(this.farmId)) {
                this.farmId = this.farmId.replace(LoginComponent.FarmIdRegex, "");
            }
            if (this.farmId && this.farmId.length > 120) {
                this.farmId = this.farmId.slice(0, 120);
            }
        }
    };
    LoginComponent.prototype.passwordChanged = function () {
        this.passwordError = false;
    };
    LoginComponent.prototype.toggleRememberMe = function () {
        this.rememberMe = !this.rememberMe;
    };
    LoginComponent.prototype.performLoginByExchangeToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tokenParameter, tokenJson, exchangeTokenData, exhcangeTokenResult, authDetails, tokenAuthEncoded, authorizedUrl, showDialog;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tokenParameter = this.getParameterByName('_t');
                        if (!tokenParameter) {
                            return [2 /*return*/, false];
                        }
                        tokenJson = atob(tokenParameter);
                        exchangeTokenData = JSON.parse(tokenJson);
                        return [4 /*yield*/, this.loginService.getReverseProxyDomain(exchangeTokenData.farmId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loginService.performExchangeToken(exchangeTokenData.token, exchangeTokenData.farmId)];
                    case 2:
                        exhcangeTokenResult = _a.sent();
                        if (exhcangeTokenResult.status == 200) {
                            authDetails = {
                                accessToken: exhcangeTokenResult.responseBody.accessToken,
                                farmId: exchangeTokenData.farmId,
                                name: exhcangeTokenResult.responseBody.userName
                            };
                            tokenAuthEncoded = window.btoa(JSON.stringify(authDetails));
                            window.sessionStorage.setItem('uc', tokenAuthEncoded);
                            authorizedUrl = this.loginService.getOriginUrl + "/" + exhcangeTokenResult.responseBody.displayVersion + "/";
                            window.location.href = authorizedUrl;
                        }
                        else {
                            showDialog = exhcangeTokenResult.status == 429;
                            this.handleErrorResponse(exhcangeTokenResult.status, exhcangeTokenResult.statusText, showDialog);
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    LoginComponent.prototype.performLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var region, hasEmptyFields, tokenLoginResponse, versionStatusResponse, loginV2Response, configurationResponse, configuration, apiVersion, authorizedUrl, headerAuthDetails, showDialog, loginResponse, apiVersion, validVersion, authorizedUrl, userData, showDialog;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        region = 'IL01';
                        this.userNameError = false;
                        this.passwordError = false;
                        this.farmIdError = false;
                        hasEmptyFields = false;
                        if (this.username == null ||
                            this.username.trim() == '') {
                            hasEmptyFields = true;
                            this.userNameError = true;
                        }
                        if (this.password == null ||
                            this.password.trim() == '') {
                            hasEmptyFields = true;
                            this.passwordError = true;
                        }
                        if (this.farmId == null ||
                            this.farmId.trim() == '') {
                            hasEmptyFields = true;
                            this.farmIdError = true;
                        }
                        if (hasEmptyFields) {
                            return [2 /*return*/];
                        }
                        this.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 16, 17]);
                        return [4 /*yield*/, this.loginService.getReverseProxyDomain(this.farmId)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loginService.performLoginV4(this.username, this.farmId, region, this.password)];
                    case 3:
                        tokenLoginResponse = _a.sent();
                        if (!(tokenLoginResponse.status != 404)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.handleLoginV4(tokenLoginResponse)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 15];
                    case 5: return [4 /*yield*/, this.loginService.getVersionStatus(this.username, this.farmId, region, this.password)];
                    case 6:
                        versionStatusResponse = _a.sent();
                        if (!(versionStatusResponse.status == 200)) return [3 /*break*/, 14];
                        if (!(versionStatusResponse.responseBody != null &&
                            versionStatusResponse.responseBody.senseTimeVersion != null)) return [3 /*break*/, 11];
                        return [4 /*yield*/, this.loginService.performLoginV2(this.username, this.farmId, region, this.password)];
                    case 7:
                        loginV2Response = _a.sent();
                        if (!(loginV2Response.status == 200)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.loginService.performLoadConfiguration(this.username, this.farmId, region, this.password)];
                    case 8:
                        configurationResponse = _a.sent();
                        if (configurationResponse.status == 200) {
                            this.storeUserDetails(region);
                            configuration = configurationResponse.responseBody;
                            apiVersion = configuration.displayVersion;
                            authorizedUrl = this.loginService.getOriginUrl + "/" + apiVersion + "/";
                            configuration.farmRole = configuration.user.farmRole;
                            configuration.username = configuration.user.username;
                            configuration.firstName = configuration.user.firstName;
                            configuration.lastName = configuration.user.lastName;
                            configuration.lastLoggedInTime = new Date();
                            configuration.appVersion = "";
                            configuration.authorizedUrl = authorizedUrl;
                            window.sessionStorage.setItem('handleResponsePolls', JSON.stringify(configuration.polls || {}));
                            window.sessionStorage.setItem('_UserInfo', this.loginService.btoa64(JSON.stringify({
                                authorization: this.loginService.encodeCredentials(this.username, this.farmId, region, this.password),
                                userData: configuration
                            })));
                            headerAuthDetails = new HeaderAuthorizationDetails();
                            headerAuthDetails.farmId = this.farmId;
                            headerAuthDetails.userName = this.username;
                            headerAuthDetails.password = this.password;
                            this.loginService.storeAngular7PlusClientAuthDetails(headerAuthDetails, this.rememberMe);
                            window.location.href = authorizedUrl;
                        }
                        else {
                            if (configurationResponse.status === 401) {
                                this.errorMessage = 'Unauthorized';
                            }
                            else {
                                this.errorMessage = configurationResponse.statusText;
                            }
                        }
                        return [3 /*break*/, 10];
                    case 9:
                        showDialog = loginV2Response.status == 400;
                        this.handleErrorResponse(loginV2Response.status, loginV2Response.statusText, showDialog);
                        _a.label = 10;
                    case 10: return [3 /*break*/, 13];
                    case 11: return [4 /*yield*/, this.loginService.performLogin(this.username, this.farmId, region, this.password)];
                    case 12:
                        loginResponse = _a.sent();
                        if (loginResponse.status == 200) {
                            this.storeUserDetails(region);
                            apiVersion = loginResponse.responseBody.version;
                            validVersion = this.loginService.validateVersion(loginResponse.responseBody.displayVersion, '1.1.2.27438', []);
                            if (validVersion > 0) {
                                apiVersion = loginResponse.responseBody.displayVersion;
                            }
                            authorizedUrl = this.loginService.getOriginUrl + "/" + apiVersion + "/";
                            window.sessionStorage.setItem('handleResponsePolls', JSON.stringify(loginResponse.responseBody.polls || {}));
                            userData = {
                                bizModeParameters: loginResponse.responseBody.bizModeParameters,
                                thirdPartyParameters: loginResponse.responseBody.thirdPartyParameters,
                                isBugReportEnabled: loginResponse.responseBody.isBugReportEnabled,
                                customerId: loginResponse.responseBody.customerId,
                                dealerId: loginResponse.responseBody.dealerId,
                                farmPackage: loginResponse.responseBody.farmPackage,
                                farmId: loginResponse.responseBody.farmId,
                                serverStatus: loginResponse.responseBody.serverStatus,
                                systemState: 'active',
                                serverStatusEndDate: loginResponse.responseBody.serverStatusEndDate,
                                farmRole: loginResponse.responseBody.user.farmRole,
                                user: loginResponse.responseBody.user,
                                username: loginResponse.responseBody.user.username,
                                firstName: loginResponse.responseBody.user.firstName,
                                farmName: loginResponse.responseBody.farmName,
                                lastName: loginResponse.responseBody.user.lastName,
                                lastLoggedInTime: new Date(),
                                appVersion: loginResponse.responseBody.version,
                                displayVersion: loginResponse.responseBody.displayVersion,
                                originalResponse: loginResponse.responseBody,
                                authorizedUrl: authorizedUrl
                            };
                            window.sessionStorage.setItem('_UserInfo', this.loginService.btoa64(JSON.stringify({
                                authorization: this.loginService.encodeCredentials(this.username, this.farmId, region, this.password),
                                userData: userData
                            })));
                            window.location.href = authorizedUrl;
                        }
                        else {
                            showDialog = loginResponse.status == 400;
                            this.handleErrorResponse(loginResponse.status, loginResponse.statusText, showDialog);
                        }
                        _a.label = 13;
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        this.errorMessage = versionStatusResponse.statusText;
                        _a.label = 15;
                    case 15: return [3 /*break*/, 17];
                    case 16:
                        this.isLoading = false;
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.copyPasswordDialogClose = function (dontShow) {
        if (dontShow) {
            window.localStorage.setItem(LoginComponent.DontShowMigrationPasswordDialogKey, 'true');
        }
        else {
            window.localStorage.removeItem(LoginComponent.DontShowMigrationPasswordDialogKey);
        }
        this.copyPasswordDialogIsOpen = false;
        this.afterLoginV4NavigateToClient();
    };
    LoginComponent.prototype.handleLoginV4 = function (tokenLoginResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var authDetails, tokenAuthEncoded, oktaLoginDetails, navigationUrl, version, dontShowMigrationPassword, response, showDialog;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(tokenLoginResponse.status == 200)) return [3 /*break*/, 4];
                        authDetails = {
                            accessToken: tokenLoginResponse.responseBody.accessToken,
                            farmId: this.farmId,
                            name: this.username
                        };
                        tokenAuthEncoded = window.btoa(JSON.stringify(authDetails));
                        window.sessionStorage.setItem('uc', tokenAuthEncoded);
                        if (tokenLoginResponse.responseBody.refreshToken) {
                            window.sessionStorage.setItem('ucr', tokenLoginResponse.responseBody.refreshToken);
                        }
                        // check if user needs to sign on eula / privacy policy and write at localstorage so it will be shown to the user.
                        if (tokenLoginResponse.responseBody.privacyPolicy || tokenLoginResponse.responseBody.eula) {
                            this.loginService.storeDocumentDetails(tokenLoginResponse.responseBody);
                        }
                        // check if remember me is toggled on - if so - store the auth details for the RP for token based versions (5.2 and on).
                        if (this.rememberMe) {
                            this.loginService.storeAngularTokenBasedClientDetails({ userName: this.username, password: this.password, farmId: this.farmId });
                        }
                        else {
                            this.loginService.removeStoredAngularTokenBasedClientDetails();
                        }
                        // clear the migration popup prompt cookie (if exists) - so it will be shown on login for specified user on specified farm
                        this.cookieService.remove(LoginComponent.BotheringCookieKeyPrefix + this.farmId + tokenLoginResponse.responseBody.userId);
                        this.cookieService.remove(LoginComponent.BotheringCookieKeyPrefix + this.farmId + this.username);
                        oktaLoginDetails = new OktaUserDetails();
                        oktaLoginDetails.setOktaUserDetails(this.username, this.password);
                        this.authorizedUrlLoginV4 = this.loginService.getOriginUrl + "/" + tokenLoginResponse.responseBody.displayVersion + "/";
                        return [4 /*yield*/, this.loginService.getOktaAccessCookieLink(oktaLoginDetails, this.loginService.getOriginUrl + "/")];
                    case 1:
                        navigationUrl = _a.sent();
                        if (navigationUrl) {
                            version = btoa(tokenLoginResponse.responseBody.displayVersion);
                            window.localStorage.setItem('lvn', version);
                            this.authorizedUrlLoginV4 = navigationUrl;
                        }
                        dontShowMigrationPassword = window.localStorage.getItem(LoginComponent.DontShowMigrationPasswordDialogKey);
                        if (!(dontShowMigrationPassword == null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.loginService.getIsUserMigrated(authDetails)];
                    case 2:
                        response = _a.sent();
                        if (response.status == 200 &&
                            !response.responseBody.isUserMigrated) {
                            this.showCopyPasswordDialog();
                            return [2 /*return*/];
                        }
                        _a.label = 3;
                    case 3:
                        this.afterLoginV4NavigateToClient();
                        return [3 /*break*/, 5];
                    case 4:
                        showDialog = tokenLoginResponse.status == 429;
                        this.handleErrorResponse(tokenLoginResponse.status, tokenLoginResponse.statusText, showDialog);
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.togglePasswordVisibility = function () {
        this.isPasswordVisible = !this.isPasswordVisible;
    };
    LoginComponent.prototype.afterLoginV4NavigateToClient = function () {
        window.location.href = this.authorizedUrlLoginV4;
    };
    LoginComponent.prototype.getParameterByName = function (name) {
        var url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
        if (!results)
            return null;
        if (!results[2])
            return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };
    LoginComponent.prototype.decodePassword = function (_v) {
        if (!_v)
            return false;
        var decoded = atob(_v);
        var splitArr = decoded.split(/[\W_]+/);
        this.username = splitArr[0];
        this.password = splitArr[3];
        this.farmId = splitArr[1];
        return true;
    };
    LoginComponent.prototype.storeUserDetails = function (region) {
        if (this.rememberMe) {
            window.localStorage.setItem('ucrp', window.btoa(this.username + "_" + this.password + "_" + this.farmId + "_" + region));
        }
        else {
            window.localStorage.removeItem('ucrp');
        }
        window.localStorage.removeItem('uc');
    };
    LoginComponent.prototype.handleErrorResponse = function (status, statusText, showDialog) {
        var _this = this;
        if (status === 401) {
            this.errorMessage = 'Unauthorized';
        }
        else if (status === 502) {
            this.errorMessage = 'No network connection, failed to login';
        }
        else if (status === 503) {
            this.errorMessage = 'Wrong farm ID';
        }
        else if (showDialog) {
            this.errorMessage = null;
            setTimeout(function () {
                var failures = [{
                        errorKey: 'You have exceeded the maximum number of Login Attempts. Please try again in 15 minutes.'
                    }];
                _this.errorDialogService.show('Too many Failed Login Attempts', failures, null);
            }, 200);
        }
        else {
            this.errorMessage = statusText;
        }
    };
    LoginComponent.prototype.showCopyPasswordDialog = function () {
        this.copyPasswordDialogIsOpen = true;
    };
    LoginComponent.EnterKey = 'Enter';
    LoginComponent.BotheringCookieKeyPrefix = 'migration-reminder';
    LoginComponent.UserNameRegex = /[^a-zA-Z0-9 \.\@\_\-]/g;
    LoginComponent.FarmIdRegex = /[^a-zA-Z0-9]/g;
    LoginComponent.DontShowMigrationPasswordDialogKey = 'dontShowMigrationPassword';
    return LoginComponent;
}());
export { LoginComponent };
