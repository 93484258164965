import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CopyPasswordLanguages } from '../copy-password-dialog.component';

@Component({
  selector: 'copy-password-language-popup',
  templateUrl: './copy-password-language-popup.component.html',
  styleUrls: ['./copy-password-language-popup.component.scss']
})
export class CopyPasswordLanguagePopupComponent implements OnInit {

  @Output()
  public onLanguageChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public copyPasswordLanguageNames : any;

  public isOpen : boolean;

  public selectedLanguage : string;

  public CopyPasswordLanguages = CopyPasswordLanguages;

  constructor() {
    this.selectedLanguage = CopyPasswordLanguages[0];
  }

  ngOnInit() {

  }

  public getLanguageName(locale: string) : string{
    return this.copyPasswordLanguageNames[locale];
  }

  public togglePopup() {
    this.isOpen = !this.isOpen;
  }

  public selectLanguage(locale: string) {
    this.selectedLanguage = locale;
    this.isOpen = false;
    this.onLanguageChanged.emit(this.selectedLanguage);
  }
}
