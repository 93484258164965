import { ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PopupViewStateService } from "../../services/ui/view-state/popup-view-state.service";
import { ClickPressEventsService } from "../../services/click-press-events/click-press-events.service";
var OutsideClickHandlerDirective = /** @class */ (function () {
    function OutsideClickHandlerDirective(popupElement, popupViewStateService, clickPressEventsService) {
        var _this = this;
        this.popupElement = popupElement;
        this.popupViewStateService = popupViewStateService;
        this.clickPressEventsService = clickPressEventsService;
        this.outsideClickIgnoreListComponents = [];
        this.outsideClickIncludeListComponents = [];
        this.onOutsideClick = this.clickPressEventsService.documentClick
            .pipe(filter(function (event) { return _this.filterClick(event); }));
    }
    OutsideClickHandlerDirective.prototype.filterClick = function (event) {
        if (event.target.parentElement != null &&
            !this.popupElement.nativeElement.contains(event.target)) {
            if (this.outsideClickIncludeListComponents != null &&
                this.outsideClickIncludeListComponents.length > 0) {
                for (var _i = 0, _a = this.outsideClickIncludeListComponents; _i < _a.length; _i++) {
                    var includeComponent = _a[_i];
                    var includeElement = this.popupViewStateService.getComponent(includeComponent);
                    if (includeElement != null &&
                        includeElement.nativeElement.contains(event.target)) {
                        return true;
                    }
                }
            }
            else {
                if (this.outsideClickIgnoreListComponents != null &&
                    this.outsideClickIgnoreListComponents.length > 0) {
                    for (var _b = 0, _c = this.outsideClickIgnoreListComponents; _b < _c.length; _b++) {
                        var ignoreComponent = _c[_b];
                        var ignoreElement = this.popupViewStateService.getComponent(ignoreComponent);
                        if (ignoreElement != null &&
                            ignoreElement.nativeElement.contains(event.target)) {
                            return false;
                        }
                    }
                }
                if (this.popupViewStateService.partOfOutsideClickIgnoreElement(event.target)) {
                    return false;
                }
                var cdkOverlayContainers = document.getElementsByClassName('cdk-overlay-container');
                if (cdkOverlayContainers.length > 0 &&
                    cdkOverlayContainers.item(0).contains(event.target)) {
                    return false;
                }
                //validate if calendar component
                if (event.target.className.toString().startsWith('owl-') ||
                    event.target.className.toString().startsWith('cdk-overlay') ||
                    (event.target.parentElement != null &&
                        (event.target.parentElement.className.toString().startsWith('owl-') ||
                            event.target.parentElement.className.toString().startsWith('cdk-overlay')))) {
                    return false;
                }
                return true;
            }
        }
    };
    return OutsideClickHandlerDirective;
}());
export { OutsideClickHandlerDirective };
