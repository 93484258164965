import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalDialogTemplateComponent } from "./components/dialogs/modal-dialog-template/modal-dialog-template.component";
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import {OutsideClickHandlerDirective} from './directives/outside-click-handler.directive';
import { CopyPasswordDialogComponent } from './components/dialogs/copy-password-dialog/copy-password-dialog.component';
import { CopyPasswordLanguagePopupComponent } from './components/dialogs/copy-password-dialog/copy-password-language-popup/copy-password-language-popup.component';

@NgModule({
  declarations: [
    ErrorDialogComponent,
    ModalDialogTemplateComponent,
    OutsideClickHandlerDirective,
    CopyPasswordDialogComponent,
    CopyPasswordLanguagePopupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
    exports: [
        ErrorDialogComponent,
        ModalDialogTemplateComponent,
        OutsideClickHandlerDirective,
        CopyPasswordDialogComponent,
    ]
})
export class ShSharedModule { }
