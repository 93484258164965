/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-dialog-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../directives/outside-click-handler.directive";
import * as i4 from "../../../../services/ui/view-state/popup-view-state.service";
import * as i5 from "../../../../services/click-press-events/click-press-events.service";
import * as i6 from "./modal-dialog-template.component";
var styles_ModalDialogTemplateComponent = [i0.styles];
var RenderType_ModalDialogTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalDialogTemplateComponent, data: {} });
export { RenderType_ModalDialogTemplateComponent as RenderType_ModalDialogTemplateComponent };
export function View_ModalDialogTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "overlay-container"]], [[8, "hidden", 0], [1, "sh-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "overlay-backdrop overlay-dark-backdrop"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "overlay-backdrop-showing": 0 }), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { backgroundColor: 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "overlay-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "overlay-pane"], ["outsideClickHandler", ""]], null, [[null, "onOutsideClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onOutsideClick" === en)) {
        var pd_0 = (_co.onOutsideModalClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(12, { "width": 0, "minHeight": 1 }), i1.ɵdid(13, 16384, null, 0, i3.OutsideClickHandlerDirective, [i1.ElementRef, i4.PopupViewStateService, i5.ClickPressEventsService], null, { onOutsideClick: "onOutsideClick" }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "overlay-backdrop overlay-dark-backdrop"; var currVal_3 = _ck(_v, 4, 0, _co.isOpen); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 7, 0, _co.overlayColor); _ck(_v, 6, 0, currVal_4); var currVal_5 = _ck(_v, 12, 0, _co.width, _co.height); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isOpen; var currVal_1 = (_co.isOpen ? "active-modal-dialog" : ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ModalDialogTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-dialog-template", [], null, null, null, View_ModalDialogTemplateComponent_0, RenderType_ModalDialogTemplateComponent)), i1.ɵdid(1, 245760, null, 0, i6.ModalDialogTemplateComponent, [i1.ElementRef, i5.ClickPressEventsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalDialogTemplateComponentNgFactory = i1.ɵccf("modal-dialog-template", i6.ModalDialogTemplateComponent, View_ModalDialogTemplateComponent_Host_0, { isOpen: "isOpen", modalWidth: "modalWidth", modalHeight: "modalHeight", overlayColor: "overlayColor" }, { outsideModalClick: "outsideModalClick", onEscapeButtonClick: "onEscapeButtonClick", onEnterButtonClick: "onEnterButtonClick" }, ["*"]);
export { ModalDialogTemplateComponentNgFactory as ModalDialogTemplateComponentNgFactory };
