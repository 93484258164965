import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ErrorDialogService = /** @class */ (function () {
    function ErrorDialogService() {
        this.popupShown = new Subject();
    }
    ErrorDialogService.prototype.show = function (headerTranslationKey, failures, onClose) {
        this.popupShown.next({ isOpen: true, failures: failures, onClose: onClose, headerTranslationKey: headerTranslationKey });
    };
    ErrorDialogService.prototype.hide = function () {
        this.popupShown.next({ isOpen: false, failures: null, errorTranslationKeysPrepend: null, headerTranslationKey: null, onClose: null });
    };
    ErrorDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorDialogService_Factory() { return new ErrorDialogService(); }, token: ErrorDialogService, providedIn: "root" });
    return ErrorDialogService;
}());
export { ErrorDialogService };
var Failure = /** @class */ (function () {
    function Failure(errorKey) {
        this.errorKey = errorKey;
    }
    return Failure;
}());
export { Failure };
